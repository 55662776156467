<template>
  <layout-content
    :loading="loading || summaryLoading"
    :children-full-screen-height="hasMore"
    @reload="reload"
    @scroll="scroll"
  >
    <div class="flex flex-col h-full after-content-none after-pt-5rem after--mb-5rem">
      <div class="overflow-x-auto overflow-y-hidden flex-shrink-0 pb-3">
        <div class="flex w-auto">
          <div class="flex-shrink-0 mr-2">
            <base-group-date-button
              v-model="date"
              :button-label="dateLabel"
              :date-format="'MM/dd'"
              :max-date="getTaipeiNowTime()"
              @click="changeSearchType"
            />
          </div>
          <div class="flex-shrink-0 mr-2">
            <transaction-filter-button
              v-model="filter"
            />
          </div>
          <div class="flex-shrink-0">
            <report-account-search
              v-model:account="account"
              history="transactionHistory"
              @click="handleAccountSearch"
              @clear="handleAccountClear"
            />
          </div>
          <div
            v-if="isDesktop"
            class="flex-shrink-0 ml-auto"
          >
            <prime-button
              class="p-button-text px-0"
              @click="exportTable"
            >
              <i class="i-ri-reply-fill mr-2px" />
              <span>
                {{ t('common.export') }}
              </span>
            </prime-button>
          </div>
        </div>
      </div>
      <agents-breadcrumb
        :agents="agents"
        class="mb-3"
        @click="goToAgent"
      />
      <base-card
        v-if="rows.length || loading"
        space
        class="mb-3"
      >
        <div class="w-full">
          <base-list-item
            :label="t('common.totalDepositCount')"
            :loading="summaryLoading"
            class="pt-0"
          >
            {{ summary?.deposit_count ?? 0 }}
          </base-list-item>
          <base-list-item
            :label="t('common.totalDepositCash')"
            :loading="summaryLoading"
          >
            <base-cash :cash="summary?.deposit_cash ?? 0" />
          </base-list-item>
          <base-list-item
            :label="t('common.totalWithdrawCount')"
            :loading="summaryLoading"
          >
            {{ summary?.withdraw_count ?? 0 }}
          </base-list-item>
          <base-list-item
            :label="t('common.totalWithdrawCash')"
            :loading="summaryLoading"
            class="pb-0"
          >
            <base-cash
              :cash="summary?.withdraw_cash ?? 0"
              :negative="true"
            />
          </base-list-item>
        </div>
      </base-card>
      <base-no-data v-if="!rows.length && !loading" />
      <base-card
        v-else
      >
        <transaction-record-table
          v-model:sort-by="sortBy"
          v-model:order="order"
          :rows="rows"
          :is-first-load="isFirstLoad"
          :loading="loading"
          :finished="finished"
          :layer="Number(currentAgent.layer)"
          @click="nextLayer"
        />
      </base-card>
    </div>
  </layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQueryStore } from '@/stores/query'
import { TransactionStatus, AccountSearchRes, AccountSearchType, TransactionReq, PaginationReq, AgentType, TransactionRes, GDKError, getTargetURL } from '@golden/gdk-agent-next'
import { usePagination, useObservable, useWatchObservable } from '@golden/shared-vue'
import gdk from '@/gdk'
import { tap, switchMap, catchError, throwError, lastValueFrom } from 'rxjs'
import { RouteNameType } from '@/constants/routeNameMapPermission'
import { useReport } from '@/functions/useReport'
import { useMediaQueryStore } from '@/stores/screen'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { goldenURL } from '@golden/url'
import { useDialog } from '@/functions/useDialog'
import TransactionExportDialog from '@/components/transaction/TransactionExportDialog.vue'
import { getTaipeiNowTime } from '@/utils/TimeHelper'

export default defineComponent({
  name: 'TransactionView',
  setup () {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const { isDesktop } = storeToRefs(useMediaQueryStore())
    const { me } = storeToRefs(useAgentStore())
    const { query, agents, currentAgent, account, handleAccountClear, handleAccountSearch: defaultHandleAccountSearch, goToAgent } = useReport('transaction')
    const store = useQueryStore()

    const sortBy = ref<'deposit_cash' | 'withdraw_cash'>()
    const order = ref<'asc' | 'desc'>('desc')
    const filter = computed({
      get () {
        return {
          ...query.value.status ? { status: Array.isArray(query.value.status) ? [...query.value.status].map(item => Number(item)) : [Number(query.value.status)] as TransactionStatus[] } : {}
        }
      },
      set (payload: { status?: TransactionStatus[] }) {
        query.value = {
          ...query.value,
          ...payload
        }

        void router.replace({
          ...route,
          query: query.value
        })
      }
    })

    const changeSearchType = () => {
      query.value = {
        ...query.value,
        search_type: query.value.search_type === 'finished_at' ? 'created_at' : 'finished_at'
      }
      void router.replace({ ...route, query: query.value })
    }

    const dateLabel = computed(() => {
      if (query.value.search_type === 'finished_at') return t('common.confirmAt')
      else if (query.value.search_type === 'created_at') return t('common.applyAt')
      return ''
    })

    const date = computed({
      get () {
        if (query.value.start_at && query.value.end_at) {
          return [new Date(Number(query.value.start_at)), new Date(Number(query.value.end_at))]
        }
        return undefined
      },
      set (value: Date[] | undefined) {
        query.value = {
          ...query.value,
          start_at: value ? value[0].getTime() : undefined,
          end_at: value ? value[1].getTime() : undefined
        }

        void router.replace({ ...route, query: query.value })
      }
    })

    const handleAccountSearch = (item: AccountSearchRes & { id_type: AccountSearchType }) => {
      if (item.id_type === AccountSearchType.PLAYER) {
        void router.push({ name: RouteNameType.TRANSACTION_DETAIL, query: query.value, params: { id: item.id } })
        return
      }
      defaultHandleAccountSearch(item)
    }

    const apiParams = computed(() => ({ ...query.value, ...filter.value, ...currentAgent.value }))

    const summaryLoading = ref(false)

    const summary = useObservable(
      useWatchObservable(() => [apiParams.value], { immediate: true })
        .pipe(
          tap(() => {
            summaryLoading.value = true
            store.setTransaction({ ...query.value })
          }),
          switchMap(({ newVal }) => {
            const params = newVal[0]
            const data = {
              search_type: params.search_type as 'created_at' | 'finished_at',
              start_at: params.start_at,
              end_at: params.end_at,
              status: params.status
            }

            return gdk.report.getTransactionSummary(data, params.id).pipe(
              catchError((error: GDKError) => {
                summaryLoading.value = false
                return throwError(() => error)
              })
            )
          }),
          tap(() => { summaryLoading.value = false })
        )
    )

    const { rows, isFirstLoad, hasMore, scroll, loading, finished } = usePagination('rows', computed(() => ({
      ...apiParams.value,
      ...{
        sort_by: sortBy.value,
        order: order.value
      }
    })), (params) => {
      const data = {
        search_type: params.search_type as 'created_at' | 'finished_at',
        start_at: params.start_at,
        end_at: params.end_at,
        status: params.status,
        page: params.page,
        sort_by: sortBy.value,
        order: order.value
      }
      return gdk.report[tableIdType.value === AccountSearchType.AGENT ? 'getTransactionAgent' : 'getTransactionPlayer'](data as TransactionReq<number> & PaginationReq, params.id)
    })

    const nextLayer = (data: TransactionRes) => {
      if (loading.value) return
      if (data.type === 1000) {
        void router.push({
          name: RouteNameType.TRANSACTION_DETAIL,
          query: {
            status: query.value.status,
            search_type: query.value.search_type,
            start_at: query.value.start_at,
            end_at: query.value.end_at
          },
          params: { id: data.id }
        })
        return
      }
      const payload = {
        id: data.id,
        layer: data.type
      }
      if (payload.id === agents.value[agents.value.length - 1].id) return
      agents.value.push({ ...payload, account: data.account, index: agents.value.length })
    }

    const tableIdType = computed(() => currentAgent.value.layer === AgentType?.STAFF ? AccountSearchType.PLAYER : AccountSearchType.AGENT)
    const reload = () => {
      query.value = { ...query.value }
    }
    const { setData } = useDialog()
    const exportTable = () => {
      setData(TransactionExportDialog, {
        id: -1,
        data: '',
        dialogData: {
          account: currentAgent.value.account ? currentAgent.value.account : me.value?.account,
          current_layer: currentAgent.value.layer,
          export_type: ref('all'),
          search_type: query.value.search_type,
          start_at: query.value.start_at,
          end_at: query.value.end_at,
          status: filter.value?.status
        },
        callback: async (payload: { data: string, id: number, dialogData: unknown }) => {
          return await lastValueFrom(gdk.report.getTransactionAgentsExport({ ...apiParams.value, search_type: apiParams.value.search_type as 'created_at' | 'finished_at', export_type: (payload.dialogData as { export_type: Ref<'all' | 'withdraw' | 'deposit'> }).export_type.value }, apiParams.value.id))
        },
        next: (data: { url: string }) => {
          const link = document.createElement('a')
          link.href = getTargetURL(goldenURL().get('api'), data.url.replace(/\/api/i, ''))
          link.click()
          link.remove()
        },
        msg: ''
      })
    }

    return {
      t,
      query,
      currentAgent,
      agents,
      filter,
      date,
      dateLabel,
      changeSearchType,
      account,
      handleAccountSearch,
      handleAccountClear,
      summary,
      summaryLoading,
      rows,
      loading,
      finished,
      isFirstLoad,
      hasMore,
      scroll,
      tableIdType,
      nextLayer,
      goToAgent,
      reload,
      sortBy,
      order,
      isDesktop,
      exportTable,
      getTaipeiNowTime
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
