<template>
  <layout-content
    :loading="loading"
    :children-full-screen-height="hasMore"
    @reload="reload"
    @scroll="scroll"
  >
    <div class="flex flex-col h-full after-content-none after-pt-5rem after--mb-5rem">
      <div class="overflow-x-auto overflow-y-hidden flex-shrink-0 pb-3">
        <div class="flex w-auto">
          <div class="flex-shrink-0 mr-2">
            <base-group-date-button
              v-model="date"
              :button-label="dateLabel"
              :date-format="'MM/dd'"
              :max-date="getTaipeiNowTime()"
              @click="changeSearchType"
            />
          </div>
          <div class="flex-shrink-0 mr-2">
            <bet-filter-button
              v-model="filter"
            />
          </div>
          <div class="flex-shrink-0">
            <report-account-search
              v-model:account="account"
              history="betHistory"
              @click="handleAccountSearch"
              @clear="handleAccountClear"
            />
          </div>
          <div
            v-if="isDesktop && currentAgent.layer !== -1"
            class="flex-shrink-0 ml-auto"
          >
            <prime-button
              class="p-button-text px-0"
              @click="exportTable"
            >
              <i class="i-ri-reply-fill mr-2px" />
              <span>
                {{ t('common.export') }}
              </span>
            </prime-button>
          </div>
        </div>
      </div>
      <agents-breadcrumb
        :agents="agents"
        class="mb-3"
        @click="goToAgent"
      />
      <base-card
        v-if="rows.length || loading"
        space
        class="mb-3"
      >
        <div class="w-full">
          <base-list-item
            v-if="(tableIdType === AccountSearchType.AGENT)"
            :label="t('common.totalBetUsers')"
            :loading="isFirstLoad"
            class="pt-0"
          >
            {{ summary?.bet_player ?? 0 }}
          </base-list-item>
          <base-list-item
            :label="t('common.totalBetCount')"
            :loading="isFirstLoad"
          >
            {{ summary?.bet_count ?? 0 }}
          </base-list-item>
          <base-list-item
            :label="t('common.totalBetCash')"
            :loading="isFirstLoad"
          >
            <base-cash
              :cash="summary?.bet_cash ?? 0"
              no-color
            />
          </base-list-item>
          <base-list-item
            :label="t('common.totalEffectiveBet')"
            :loading="isFirstLoad"
          >
            <base-cash
              :cash="summary?.effective_bet ?? 0"
              no-color
            />
          </base-list-item>
          <base-list-item
            :label="t('common.totalRevenue')"
            :loading="isFirstLoad"
          >
            <base-cash :cash="summary?.revenue ?? 0" />
          </base-list-item>
        </div>
      </base-card>
      <base-no-data v-if="!rows.length && !loading" />
      <base-card
        v-else
      >
        <bet-table
          :rows="rows"
          :is-first-load="isFirstLoad"
          :loading="loading"
          :finished="finished"
          :layer="Number(currentAgent.layer)"
          @click="nextLayer"
        />
      </base-card>
    </div>
  </layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQueryStore } from '@/stores/query'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { BetStatus, AccountSearchType, BetReq, PaginationReq, AgentType, BetAgentRes, BetPlayerRes, PaginationRes, BetPlayerSummaryRes, getTargetURL } from '@golden/gdk-agent-next'
import { usePagination } from '@golden/shared-vue'
import gdk from '@/gdk'
import { RouteNameType } from '@/constants/routeNameMapPermission'
import { useReport } from '@/functions/useReport'
import { Observable, lastValueFrom, map } from 'rxjs'
import { useDialog } from '@/functions/useDialog'
import BetExportDialog from '@/components/bet/BetExportDialog.vue'
import { useMediaQueryStore } from '@/stores/screen'
import { goldenURL } from '@golden/url'
import { getTaipeiNowTime } from '@/utils/TimeHelper'
import { mergeArray } from '@golden/utils'
import { useGames } from '@/functions/useGames'

export default defineComponent({
  name: 'BetView',
  setup () {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const { isDesktop } = storeToRefs(useMediaQueryStore())
    const { me } = storeToRefs(useAgentStore())
    const { query, agents, currentAgent, account, handleAccountSearch, handleAccountClear, goToAgent } = useReport('bet')
    const store = useQueryStore()
    const { games } = useGames()

    const filter = computed({
      get () {
        return {
          ...query.value.transaction_status ? { transaction_status: Array.isArray(query.value.transaction_status) ? [...query.value.transaction_status].map(item => Number(item)) : [Number(query.value.transaction_status)] as BetStatus[] } : {},
          ...query.value.games ? { games: Array.isArray(query.value.games) ? [...query.value.games].map(item => Number(item)) : [Number(query.value.games)] } : {}
        }
      },
      set (payload: { transaction_status?: BetStatus[], games?: number[] }) {
        query.value = {
          ...query.value,
          ...payload
        }

        void router.replace({
          ...route,
          query: query.value
        })
      }
    })

    const changeSearchType = () => {
      query.value = {
        ...query.value,
        search_type: query.value.search_type === 'settled_at' ? 'created_at' : 'settled_at'
      }
      void router.replace({ ...route, query: query.value })
    }

    const dateLabel = computed(() => {
      if (query.value.search_type === 'settled_at') return t('common.settledAt')
      else if (query.value.search_type === 'created_at') return t('common.betAt')
      return ''
    })

    const date = computed({
      get () {
        if (query.value.start_at && query.value.end_at) {
          return [new Date(Number(query.value.start_at)), new Date(Number(query.value.end_at))]
        }
        return undefined
      },
      set (value: Date[] | undefined) {
        query.value = {
          ...query.value,
          start_at: value ? value[0].getTime() : undefined,
          end_at: value ? value[1].getTime() : undefined
        }

        void router.replace({ ...route, query: query.value })
      }
    })

    const tableIdType = computed(() => currentAgent.value.layer === -1 ? AccountSearchType.PLAYER : AccountSearchType.AGENT)

    const apiParams = computed(() => {
      const { id, layer, account, ...restQuery } = { ...query.value, ...filter.value, ...currentAgent.value }
      let transactionStatus: BetStatus | undefined

      if (query.value.transaction_status && (query.value.transaction_status as BetStatus[]).length === 1) {
        transactionStatus = (query.value.transaction_status as BetStatus[])[0]
      }
      const staffId = tableIdType.value === AccountSearchType.PLAYER ? { staff_id: agents.value[agents.value.length - 2]?.id } : {}
      return ({ ...restQuery, [tableIdType.value === AccountSearchType.AGENT ? 'agent_id' : 'user_id']: id, transaction_status: transactionStatus, ...staffId })
    })
    const { rows, lastRes, isFirstLoad, hasMore, scroll, loading, finished, reload } = usePagination('rows', apiParams, (params): Observable<PaginationRes<unknown[]>> => {
      store.setBet({ ...query.value })
      const payload = params as BetReq<number> & { agent_id: number, user_id: number } & PaginationReq
      if (tableIdType.value === AccountSearchType.AGENT) return gdk.report.getBetAgent(payload)
      return gdk.report.getBetPlayer(payload).pipe(
        map((res) => ({
          ...res,
          data: mergeArray(games.value, res.data, (game, item) => game.id === item.game)
            .map(([game, item]) => ({ gameName: game.instance.name, ...item }))
        }))
      )
    })

    const summary = computed(() => {
      return lastRes.value as unknown as BetPlayerSummaryRes
    })

    const nextLayer = (data: BetPlayerRes | BetAgentRes) => {
      if (loading.value) return
      const staffId = agents.value[agents.value.length - 2]?.id
      if ('game' in data) {
        // 單人場館詳情
        // 帶上經銷 id
        void router.push({
          name: RouteNameType.BET_DETAIL,
          query: { ...query.value, game_id: data.game, user_id: summary.value.id, staff_id: staffId, category: data.game_category }
        })
      } else {
        const payload = {
          id: data.id ?? 0,
          layer: currentAgent.value.layer === AgentType.STAFF ? -1 : (Number(currentAgent.value.layer) + 1) as AgentType,
          account: data.account
        }
        agents.value.push({ ...payload, index: agents.value.length })
      }
    }
    const { setData } = useDialog()
    const exportTable = () => {
      setData(BetExportDialog, {
        id: -1,
        data: '',
        dialogData: {
          account: currentAgent.value.account ? currentAgent.value.account : me.value?.account,
          current_layer: currentAgent.value.layer,
          export_type: ref(),
          search_type: query.value.search_type,
          start_at: query.value.start_at,
          end_at: query.value.end_at,
          transaction_status: filter.value?.transaction_status,
          games: filter.value?.games
        },
        callback: async (payload: { data: string, id: number, dialogData: unknown }) => {
          return await lastValueFrom(gdk.report.getBetAgentExport({ ...apiParams.value, agent_id: currentAgent.value.id, export_type: (payload.dialogData as { export_type: Ref<AgentType> }).export_type.value }))
        },
        next: (data: { url: string }) => {
          const link = document.createElement('a')
          link.href = getTargetURL(goldenURL().get('api'), data.url.replace(/\/api/i, ''))
          link.click()
          link.remove()
        },
        msg: ''
      })
    }

    return {
      t,
      query,
      currentAgent,
      agents,
      filter,
      date,
      dateLabel,
      changeSearchType,
      account,
      handleAccountSearch,
      handleAccountClear,
      rows: rows as Ref<Array<BetAgentRes | BetPlayerRes>>,
      summary,
      loading,
      isFirstLoad,
      hasMore,
      finished,
      scroll,
      reload,
      tableIdType,
      AccountSearchType,
      nextLayer,
      goToAgent,
      exportTable,
      isDesktop,
      getTaipeiNowTime
    }
  }
})
</script>
