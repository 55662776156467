<template>
  <div class="py-2">
    <div class="py-7px">
      <span>
        {{ t('common.account') }}：
      </span>
      <span>
        {{ account }}
      </span>
    </div>
    <div class="py-7px flex-row-start">
      <span>
        {{ t('common.layer') }}：
      </span>
      <div class="flex-1">
        <prime-dropdown
          v-model="dialogRef.data.dialogData.export_type"
          class="important-px-0 w-full"
          :options="layerOptions"
          option-label="name"
          option-value="value"
          :placeholder="t('placeholder.select')"
        />
      </div>
    </div>
    <div
      v-if="dialogRef.data.dialogData.export_type"
      class="color-red-600 pb-7px mt-1px pl-32px"
    >
      {{ t('message.export.betTip', { account: account, layer }) }}
    </div>
    <div class="py-7px">
      <span>
        {{ t('common.date') }}：
      </span>
      <span>
        {{ dateLabel }} {{ date }}
      </span>
    </div>
    <div class="py-7px">
      <span>
        {{ t('common.orderStatus') }}：
      </span>
      <span>
        {{ transactionStaus }}
      </span>
    </div>
    <div class="py-7px">
      <span>
        {{ t('common.gamePlatform') }}：
      </span>
      <span>
        {{ games }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { BetStatus, AgentType } from '@golden/gdk-agent-next'
import { defineComponent, computed, inject, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { LayerName } from '@/constants/StatusMapping'
import { formatTaipeiTime } from '@/utils/TimeHelper'
import { useGames } from '@/functions/useGames'

export default defineComponent({
  name: 'BetExportDialog',
  setup () {
    const { t } = useI18n()
    const dialogRef = inject('dialogRef') as Ref<{ data: { header: string, disabled: Ref<boolean>, dialogData: { account: string, current_layer: AgentType, export_type: AgentType | undefined, transaction_status: BetStatus[], games: number[], search_type: 'created_at' | 'settled_at', start_at: number, end_at: number } } }>
    dialogRef.value.data.header = `${t('common.confirm')}${t('common.export')}`
    dialogRef.value.data.disabled = computed(() => !dialogRef.value.data.dialogData.export_type)

    const account = computed(() => dialogRef.value.data.dialogData.account)
    const layerOptions = computed(() => ([
      {
        name: t(LayerName[AgentType.EMPEROR]),
        value: AgentType.EMPEROR
      },
      {
        name: t(LayerName[AgentType.BOSS]),
        value: AgentType.BOSS
      },
      {
        name: t(LayerName[AgentType.MANAGER]),
        value: AgentType.MANAGER
      },
      {
        name: t(LayerName[AgentType.LEADER]),
        value: AgentType.LEADER
      },
      {
        name: t(LayerName[AgentType.STAFF]),
        value: AgentType.STAFF
      },
      {
        name: t(LayerName[1000]),
        value: 1000
      }
    ]
      .filter((option) => option.value > dialogRef.value.data.dialogData.current_layer)
    ))

    const dateLabel = computed(() => {
      if (dialogRef.value.data.dialogData.search_type === 'settled_at') return t('common.settledAt')
      else if (dialogRef.value.data.dialogData.search_type === 'created_at') return t('common.betAt')
      return ''
    })
    const date = computed(() => `${formatTaipeiTime(new Date(Number(dialogRef.value.data.dialogData.start_at)), 'MM/dd')} - ${formatTaipeiTime(new Date(Number(dialogRef.value.data.dialogData.end_at)), 'MM/dd')}`)
    const transactionStaus = computed(() => {
      const status = dialogRef.value.data.dialogData.transaction_status
      if (status?.length === 2 || status?.length === 0 || !status) return t('common.all')
      if (status[0] === BetStatus.SETTLE) return t('common.settled')
      if (status[0] === BetStatus.UNSETTLE) return t('common.unsettled')
      return ''
    })

    const { getGameName } = useGames()
    const games = computed(() => {
      const games = dialogRef.value.data.dialogData.games
      if (!games || !games.length) return t('common.all')
      return games.map((game) => getGameName(game)).join('、')
    })

    const layer = computed(() => dialogRef.value.data.dialogData.export_type ? t(LayerName[dialogRef.value.data.dialogData.export_type]) : '')

    return {
      t,
      dialogRef,
      account,
      layerOptions,
      dateLabel,
      date,
      transactionStaus,
      games,
      layer
    }
  }
})
</script>
